/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 21:00:41.611
 * @desc generated by yqg-cli@0.1.12
 */

import {EASEMOB} from 'moneycan/common/constant/config';

import {connect} from 'ssr-common/vue/component/easemob/easemob';

export default {
    name: 'HeaderNav',
    methods: {
        connect: () => connect(EASEMOB)
    }
};

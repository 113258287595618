var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "header-nav" },
    [
      _c("router-link", { attrs: { to: { name: "index" } } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("./img/logo.png") },
        }),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "nav-list" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "index" } } }, [
              _vm._v("Home"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { path: "/index#products" } } }, [
              _vm._v("Our Products"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "security" } } }, [
              _vm._v("Security"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", [
          _c("a", { on: { click: _vm.connect } }, [_vm._v("Contact Us")]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 21:01:25.469
 * @desc generated by yqg-cli@0.1.12
 */

export default {
    name: 'FooterBar'
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer-bar" }, [
      _c("img", {
        staticClass: "footer-icon",
        attrs: { src: require("./img/footer-icon.png") },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "footer-info" }, [
        _vm._v("Copyright © 2018 Fintopia Inc. All rights reserved."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }